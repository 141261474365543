import { FC } from "react";

const DesktopPage: FC<{
  mobileOnlyExperienceImageUrl: string;
}> = ({ mobileOnlyExperienceImageUrl }) => {
  if (mobileOnlyExperienceImageUrl) {
    return (
      <div
        className="justify-center items-center flex flex-col w-screen h-screen bg-cover bg-center"
        style={{
          backgroundImage: 'url("' + mobileOnlyExperienceImageUrl + '")',
        }}
      ></div>
    );
  } else {
    return (
      <div className="justify-center items-center flex flex-col w-screen h-screen">
        Visit this site on your mobile device to experience the immersive 3D.
      </div>
    );
  }
};

export default DesktopPage;
